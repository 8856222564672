var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fcd102b8071ff98c658b468896209b75d1d5fbeb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { Replay } from '@sentry/replay'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment:
    process.env.ENVIRONMENT != null
      ? process.env.ENVIRONMENT
      : process.env.NODE_ENV,
  integrations: [
    new Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        window.location.origin,
        process.env.API_BASE ?? '',
        process.env.HP_BASE ?? '',
        process.env.WSS_BASE ?? '',
      ],
      networkCaptureBodies: true,
      stickySession: true,
    }),
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    process.env.API_BASE ?? '',
    process.env.HP_BASE ?? '',
    process.env.WSS_BASE ?? '',
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
})
